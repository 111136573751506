import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Table, Pagination } from 'react-bootstrap';
import CertificateUploader from './utilityComponents/CertificateUploader';

const UsersWithCertificates = ({ renderView, instructorData }) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`https://www.opecstat.com/opecstat_server/course/usersWithCertificates/${instructorData._id}`);
      setData(response.data.data || []);
      //console.log(JSON.stringify(instructorData,null,2))
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [instructorData._id]);

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container mt-5">
      <h2 className="text-center">Users with Certificates</h2>

      {loading ? (
        <div className="text-center">Loading...</div>
      ) : (
        <>
        <div>
            
        <Button
                      variant="primary"
                      onClick={() => setShowModal(true)}
                    >
                      Award Certificate
                    </Button>
        </div>
          <Table bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>User Name</th>
                <th>Course Title</th>
                <th>Certificate</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td>{indexOfFirstItem + index + 1}</td>
                  <td>{item.userName}</td>
                  <td>{item.courseTitle}</td>
                  <td>
                    <a href={item.certImage} target="_blank" rel="noopener noreferrer">
                      View Certificate
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* Pagination */}
          <Pagination>
            {Array.from({ length: Math.ceil(data.length / itemsPerPage) }, (_, idx) => (
              <Pagination.Item
                key={idx + 1}
                active={idx + 1 === currentPage}
                onClick={() => handlePageChange(idx + 1)}
              >
                {idx + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </>
      )}

      {/* Modal for Awarding Certificate */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Award Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CertificateUploader instructorData={instructorData} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UsersWithCertificates;
