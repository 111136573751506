// Import necessary styles
import VerifyPageView from '../components/marketing/VerifyPageView';

const Verify = () => {

  return (
    <VerifyPageView/>

  );
};

export default Verify;
