import React from 'react';

const BadgeExplanation = () => {
  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card shadow-lg">
            <div className="card-header text-center bg-primary text-white">
              <h2>Opecstat Academy Excellence Badge</h2>
            </div>
            <div className="card-body">
              <h3 className="text-center">Congratulations on Earning Your Badge!</h3>
              <p className="lead text-center mb-4">
                The Opecstat Academy Excellence Badge is a symbol of your hard work, dedication, and achievement throughout your learning journey. It represents your commitment to mastering the course material and your continuous pursuit of professional growth.
              </p>

              <h4>How You Earned Your Badge:</h4>
              <ul className="list-group list-group-flush mb-4">
                <li className="list-group-item">
                  <strong>Watching Lessons:</strong> You actively engaged with all course lessons, ensuring a thorough understanding of the content.
                </li>
                <li className="list-group-item">
                  <strong>Replicating Lessons:</strong> You not only watched the lessons but also took the initiative to replicate the exercises and implement them independently.
                </li>
                <li className="list-group-item">
                  <strong>Taking Quizzes:</strong> You completed all quizzes with dedication, testing your understanding and reinforcing key concepts.
                </li>
                <li className="list-group-item">
                  <strong>Completing Assignments:</strong> You submitted all assignments on time, demonstrating your ability to apply the knowledge gained.
                </li>
                <li className="list-group-item">
                  <strong>Brainstorming with Your AI Learning Assistant:</strong> You leveraged the AI learning assistant to brainstorm solutions, tackle challenges, and refine your understanding of complex topics.
                </li>
              </ul>

              <h4>Why This Badge Matters:</h4>
              <p className="mb-4">
                This badge not only reflects your commitment to learning but also enhances your professional profile. It showcases your ability to master challenging concepts, complete real-world tasks, and collaborate with cutting-edge AI tools to solve problems. Display this badge proudly as it can serve as a valuable credential in your career journey.
              </p>

              <h5>What’s Next?</h5>
              <p>
                With your achievement, you are now well-prepared to take the next step in your professional development. We encourage you to continue building on the foundation you’ve established, whether by advancing to the next module or applying your skills in real-world projects.
              </p>
            </div>
            {/*<div className="card-footer text-center">
              <button className="btn btn-primary">Download Your Badge</button>
            </div>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BadgeExplanation;
