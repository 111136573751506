import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const CertificatePayment = () => {
  const certificatePrice = 15000;
  const discountPrice = 1000; // Corrected discounted price
  const discountDuration = 600; // 10 minutes in milliseconds

  const [timeLeft, setTimeLeft] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(certificatePrice);

  useEffect(() => {
    const updatePrice = () => {
      const currentTime = new Date().getTime();
      let expiryTime = Cookies.get('offerExpiryTime');

      if (!expiryTime || currentTime > expiryTime) {
        // Set new expiry time if it doesn't exist or expired
        expiryTime = currentTime + discountDuration;
        Cookies.set('offerExpiryTime', expiryTime, { expires: 1 }); // Cookie lasts for 1 day
      }

      const remainingTime = expiryTime - currentTime;
      setTimeLeft(remainingTime);

      const price = remainingTime > 0 ? discountPrice : certificatePrice;
      setCurrentPrice(price);
    };

    updatePrice(); // Initial update when component is mounted
    const intervalId = setInterval(updatePrice, 1000); // Update every second

    return () => {
      clearInterval(intervalId); // Clean up the interval on unmount
    };
  }, []);

  const formatTimeLeft = (time) => {
    const minutes = Math.floor(time / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return `${minutes}m ${seconds}s`;
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6">
          <div className="card shadow-lg rounded" style={{ backgroundColor: '#2c2c2c', color: '#fff' }}>
            <div className="card-header text-center bg-dark text-white py-4">
              <h4>Get Your Opecstat Certificate</h4>
            </div>
            <div className="card-body">
              <div className="text-center mb-4">
                <h5 className="card-title">Why Choose Opecstat?</h5>
                <p className="card-text">Earn a globally recognized certificate that boosts your career!</p>
                <ul className="list-unstyled text-left">
                  <li>🌍 Internationally recognized qualification</li>
                  <li>💼 Enhance your job prospects</li>
                  <li>🌐 Network with industry professionals</li>
                  <li>🏆 Boost your credibility and reputation</li>
                </ul>
              </div>

              <div className="d-flex justify-content-center mb-3">
                <div className="text-center">
                  <h5 className="text-danger font-weight-bold">
                    Time Left: <span className="timer">{formatTimeLeft(timeLeft)}</span>
                  </h5>
                  <p className="font-weight-bold text-success">
                    Discount Price: <span className="display-price">₦{currentPrice.toLocaleString()}</span>
                  </p>
                  <p className="text-muted">
                    <><strong>Original Price:</strong> ₦15,000</>
                  </p>
                </div>
              </div>

              <div className="text-center">
                <button className="btn btn-success btn-lg w-100" style={{ backgroundColor: '#28a745' }}>
                  Claim Your Certificate Now
                </button>
              </div>
            </div>
            <div className="card-footer text-center py-3">
              <p className="text-muted small">Offer valid until the timer runs out. Act fast!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificatePayment;
