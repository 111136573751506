import { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import useFetch from '../../custom_hooks/useFetch';
import subImage from '../../../assets/images/wo.avif';
import ChatComponent from './ChatBox';
import QuizComponent from "./QuizComponent";
import AssignmentComponent from "./AssignmentComponent";
import TextbookComponent from "./TextbookComponent";
import { useNavigate } from "react-router-dom";

const ResourceControlComponent = ({ videoData = null, userData, type, handleTestTaken = null }) => {
  const [showModal, setShowModal] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const navigate = useNavigate()

  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.paystack.co/v1/inline.js';
    script.async = true;
    script.onload = () => setIsScriptLoaded(true);
    script.onerror = () => alert('Failed to load payment gateway. Please try again later.');
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  const handleSubscription = async () => {
    if (!isScriptLoaded) {
      alert('Payment gateway not ready. Please wait and try again.');
      return;
    }

    // Determine the subscription amount
    const dAmount = userData.sub_status === 50 ? 10000 : 7000;

    // Initialize Paystack payment
    const handler = window.PaystackPop.setup({
      key: 'pk_live_6908f07ce5411bb8a5224fb4dbfaa0c63b1c6b4c', // Replace with your Paystack public key
      email: userData.email,
      amount: dAmount * 100, // Amount in kobo
      currency: 'NGN',
      ref: `tx-${Math.floor(Math.random() * 1000000)}`, // Unique transaction reference
      metadata: {
        custom_fields: [
          {
            display_name: userData.name,
            variable_name: 'user_id',
            value: userData._id,
          },
        ],
      },
      callback: (response) => {
        // Handle successful payment
        if (response.status === 'success') {
          fetch('https://opecstat.com/opecstat_server/user/subscribe', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userId: userData._id }),
          })
            .then((res) => {
              if (res.ok) {
                alert('Subscription successful!');
                navigate(0)
              } else {
                alert('Failed to update subscription status.');
              }
            })
            .catch(() => {
              alert('An error occurred while updating your subscription.');
            });
        } else {
          alert('Payment was not successful.');
        }
      },
      onClose: () => {
        console.log('Payment cancelled!');
      },
    });

    // Open the payment modal
    handler.openIframe();
  };

  {/*useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.flutterwave.com/v3.js';
    script.async = true;
    script.onload = () => setIsScriptLoaded(true);
    script.onerror = () => alert('Failed to load payment gateway. Please try again later.');
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  const handleSubscription = async () => {
    if (!isScriptLoaded) {
      alert('Payment gateway not ready. Please wait and try again.');
      return;
    }
    let dAmount = userData.sub_status === 50 ? 10000 : 15000;
    const paymentData = {
      tx_ref: `tx-${Math.floor(Math.random() * 1000000)}`,
      amount: dAmount,
      currency: "NGN",
      payment_type: "card",
      email: userData.email,
      name: userData.name,
      custom_fields: [{ display_name: userData.name, variable_name: "user_id", value: userData._id }],
    };
    window.FlutterwaveCheckout({
      public_key: "FLWPUBK-3ac77063a52ca467ead928d3af1948f6-X",
      tx_ref: paymentData.tx_ref,
      amount: paymentData.amount,
      currency: paymentData.currency,
      //payment_plan: 129677,
      payment_options: "card, banktransfer, ussd",
      customer: { email: paymentData.email, phone_number: "08139582196", name: paymentData.name },
      customizations: { title: "Opecstat Subscription", description: "Subscribe for premium access", logo: "https://opecstat.com/static/media/opec10.0ff41e7823a84d193d60.png" },
      callback: function (data) {
        if (data.status === "successful") {
          fetch('https://opecstat.com/opecstat_server/user/subscribe', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userId: userData._id }),
          })
          .then(response => response.ok ? alert("Subscription successful!") : alert("Failed to update subscription status."))
          .catch(() => alert("An error occurred while updating your subscription."));
        } else alert("Payment was not successful.");
      },
      onclose: function() { console.log("Payment cancelled!"); }
    });
  };*/}

  const handleModalShow = () => {
    if (type === "quiz");
    setShowModal(true);
  };
  const handleModalClose = () => setShowModal(false);
  const handleChatModalShow = () => setShowChatModal(true);
  const handleChatModalClose = () => setShowChatModal(false);

  return (
    <div>
      {type === "textbook" ? (
        <Button onClick={handleModalShow} className="btn btn-warning btn-sm rounded-lg p-3 flex-fill fw-bold text-white w-100">
          <center><i className="fa fa-fw fa-book fs-2"></i><br />Downloads</center>
        </Button>
      ) : type === "quiz" ? (
        <Button onClick={handleModalShow} className="btn btn-success btn-sm rounded-lg p-3 flex-fill fw-bold text-white w-100">
          <center><i className="fa fa-fw fa-question-circle fs-2"></i><br />Take Lesson Quiz</center>
        </Button>
      ) : type === "assignment" ? (
        <Button onClick={handleModalShow} className="btn btn-dark btn-sm rounded-lg p-3 flex-fill fw-bold text-white cursor-pointer w-100">
          <center>
            <i className="fa fa-fw fa-file-alt fs-2"></i>
            <br />
            View Assignment
          </center>
        </Button>
      ) : type === "sourceCode" ? (
        <Button onClick={handleModalShow} className="btn btn-danger btn-sm rounded-lg p-3 flex-fill fw-bold text-white cursor-pointer w-100">
          <center>
            <i className="fa fa-fw fa-pencil-alt fs-2"></i>
            <br />
            Download Source Code
          </center>
        </Button>
      ) : type === "chat" ? (
        <Button onClick={handleChatModalShow} className="btn btn-primary rounded-lg p-3 flex-fill fw-bold text-white cursor-pointer w-100">
          <center>
            <i className="fa fa-fw fa-comment fs-2"></i>
            <br />
            Ask me Questions
          </center>
        </Button>
      ) : type === "subscribe" ? (
        <Button onClick={handleModalShow} className="btn btn-info rounded-lg p-3 flex-fill fw-bold text-white cursor-pointer w-100">
          <center>
            <i className="fas fa-arrow-right"></i> {`${userData.sub_status === 50?' Subscribe Back Now @ just N10,000':'Get Your Certificate @ just ₦7,000 Now!'}`}
          </center>
        </Button>
      ) : null}


      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton><Modal.Title>{type === "quiz" ? "Quiz" : "Resource Details"}</Modal.Title></Modal.Header>
        <Modal.Body>
          {
            userData.sub_status !== 50 && userData.sub_status !== 60 ? (
              type === "quiz" ? (
                <QuizComponent
                videoData={videoData}
                userData={userData}
                handleTestTaken = {handleTestTaken}
              />
              ) : type === "assignment" ? (
                <AssignmentComponent
                  videoData={videoData} 
                />
              ): type === "textbook" ? (
                <TextbookComponent
                  videoData={videoData} 
                />
              ):(
                <div>No Resource Available...</div>
              )
            ):(
                
                  userData.sub_status == 60 ? (
                    <div className="row text-dark" style={{ overflow: "auto" }}>
                      <div className="h-75 text-center">
                        <center><img src={subImage} className="h-100 w-75 img-fluid mb-4" alt="side_image" /></center>
                        <h4 className="fw-bold">Stay Ahead in Tech!</h4>
                        <h5 className="mb-4">Get your certificate and unlock access to premium features for just <s>₦67,000</s> ₦7,000</h5>
                      </div>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item"><i className="fas fa-infinity text-primary"></i> Lifetime Access to All Courses enrolled for</li>
                        <li className="list-group-item"><i className="fas fa-comment text-secondary"></i> Questions and Interactions with Lucy</li>
                        <li className="list-group-item"><i className="fas fa-book-open text-success"></i> Unlimited Access to High-Value Textbooks</li>
                        <li className="list-group-item"><i className="fas fa-question text-warning"></i> Test Your Knowledge with Engaging Quizzes</li>
                        <li className="list-group-item"><i className="fas fa-tasks text-info"></i> Access to Assignments to Sharpen Your Skills</li>
                        <li className="list-group-item"><i className="fas fa-code text-danger"></i> Downloadable Source Code for Each Class</li>
                        <li className="list-group-item"><i className="fas fa-video text-secondary"></i> Exclusive Video Tutorials and Live Sessions</li>
                        <li className="list-group-item"><i className="fas fa-trophy text-primary"></i> Certification Upon Completion of Each Course</li>
                        {/*<li className="list-group-item"><i className="fas fa-users text-success"></i> Access to an Active Community of Learners</li>*/}
                      </ul>
                      {/* Subscribe Button */}
                      <div className="text-center">
                        <button onClick={handleSubscription} className="btn btn-success btn-lg">
                          <i className="fas fa-arrow-right"></i> Get Your Certificate @ just ₦7,000 Now!
                        </button>
                      </div>
                    </div>
                  ):(
                    <div className="row text-dark" style={{ overflow: "auto" }}>
                      <div className="h-75 text-center">
                        <center><img src={subImage} className="h-100 w-75 img-fluid mb-4" alt="side_image" /></center>
                        <h3 className="fw-bold">Stay Ahead in Tech!</h3>
                        <h5 className="mb-4">Re-enroll and Unlock access to premium features for just <s>₦67,000</s> ₦10,000</h5>
                      </div>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item"><i className="fas fa-infinity text-primary"></i> Lifetime Access to All Courses enrolled for</li>
                        <li className="list-group-item"><i className="fas fa-comment text-secondary"></i> Questions and Interactions with Lucy</li>
                        <li className="list-group-item"><i className="fas fa-book-open text-success"></i> Unlimited Access to High-Value Textbooks</li>
                        <li className="list-group-item"><i className="fas fa-question text-warning"></i> Test Your Knowledge with Engaging Quizzes</li>
                        <li className="list-group-item"><i className="fas fa-tasks text-info"></i> Access to Assignments to Sharpen Your Skills</li>
                        <li className="list-group-item"><i className="fas fa-code text-danger"></i> Downloadable Source Code for Each Class</li>
                        <li className="list-group-item"><i className="fas fa-video text-secondary"></i> Exclusive Video Tutorials and Live Sessions</li>
                        <li className="list-group-item"><i className="fas fa-trophy text-primary"></i> Certification Upon Completion of Each Course</li>
                        {/*<li className="list-group-item"><i className="fas fa-users text-success"></i> Access to an Active Community of Learners</li>*/}
                      </ul>
                      {/* Subscribe Button */}
                      <div className="text-center">
                        <button onClick={handleSubscription} className="btn btn-success btn-lg">
                          <i className="fas fa-arrow-right"></i> Subscribe Back Now @ just &#8358;10,000
                        </button>
                      </div>
                    </div>
                  )
          )}
        </Modal.Body>
        <Modal.Footer><Button variant="secondary" onClick={handleModalClose}>Close</Button></Modal.Footer>
      </Modal>

      {/* Chat Modal */}
      <Modal show={showChatModal} onHide={handleChatModalClose}>
        <Modal.Header closeButton><Modal.Title><b>Hello, I’m Lucy!</b></Modal.Title></Modal.Header>
        <Modal.Body><ChatComponent user={userData} video={videoData} /></Modal.Body>
        <Modal.Footer><Button variant="secondary" onClick={handleChatModalClose}>Close</Button></Modal.Footer>
      </Modal>
    </div>
  );
};

export default ResourceControlComponent;
