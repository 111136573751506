import { useState, useEffect } from 'react';
import { ListGroup, Alert } from 'react-bootstrap';
import useFetch from '../../custom_hooks/useFetch';

const TextbookComponent = ({ videoData }) => {
  const [textbooks, setTextbooks] = useState([]);

  const { result, isResultPending, resultError } = useFetch(
    `https://opecstat.com/opecstat_server/textbook/getTextbooksByVideoId/${videoData._id}`
  );

  useEffect(() => {
    if (!isResultPending && result) {
      setTextbooks(result.textbook || []); // Assuming the API returns a `textbooks` field
    }
    console.log(JSON.stringify(result,null,2))
  }, [result, isResultPending]);

  return (
    <div style={{ padding: "5px", color: "black" }}>
      <h5>Available Resources</h5>
      {isResultPending ? (
        <Alert variant="info">Loading textbooks...</Alert>
      ) : resultError ? (
        <Alert variant="warning">No textbooks available...</Alert>
      ) : textbooks.length ? (
        <ListGroup>
          {textbooks.map((textbook, index) => (
            <ListGroup.Item key={index}>
              <strong>{textbook.title}</strong><br />
              <a href={`https://www.opecstat.com/opecstat_server/download/${textbook.link}`} target="_blank" rel="noopener noreferrer">
                Download
              </a>
            </ListGroup.Item>
          ))}
        </ListGroup>
      ) : (
        <Alert variant="warning">No textbooks available for this video.</Alert>
      )}
    </div>
  );
};

export default TextbookComponent;
