import React, { useState, useEffect, useCallback } from 'react';
import "../../assets/fonts/fontawesome-free/css/all.min.css";
import "../../assets/css/sb-admin-2.min.css";
import Sidebar from "../../components/instructorDashboard/Sidebar";
import Topbar from "../../components/instructorDashboard/Topbar";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import loader1 from '../../assets/images/03-42-11-849_512.gif';
import MainView from '../../components/instructorDashboard/MainView';
import ViewCourses from '../../components/instructorDashboard/ViewCourses';
import ViewSubCourses from '../../components/instructorDashboard/ViewSubCourses';
import ViewLessons from '../../components/instructorDashboard/ViewLessons';
import UsersWithCertificates from '../../components/instructorDashboard/ViewCerts';

const InstructorDashboard = () => {
    const [instructorData, setInstructorData] = useState(null);
    const [currentView, setCurrentView] = useState("home");
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [variableData, setVariableData] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const fetchInstructorData = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login', { state: { serverError: 'Please log in to access the dashboard.' } });
            }
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            };
            const response = await axios.get('https://www.opecstat.com/opecstat_server/instructor/getInstructor', config);
            if (!response.status || response.status !== 200) {
                navigate('/login', { state: { serverError: 'Please log in to access the dashboard.' } });
            }
            setInstructorData(response.data.instructor);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }, [navigate]);

    useEffect(() => {
        fetchInstructorData();
    }, [fetchInstructorData]);

    useEffect(() => {
        if (instructorData && instructorData.formFilled === 0) {
            setCurrentView("onboard");
            window.location.hash = "onboard";
        }
    }, [instructorData]);

    useEffect(() => {
        const handleHashChange = () => {
            const hash = window.location.hash.slice(1);
            if (hash) {
                setCurrentView(hash);
            }
        };
        window.addEventListener("hashchange", handleHashChange);
        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, []);

    const renderView = (view, data = null) => {
        setCurrentView(view);
        setVariableData(data);
        window.location.hash = view; // Update hash for navigation
    };

    const getViewComponent = () => {
        switch (currentView) {
            case 'home':
                return <MainView renderView={renderView} instructorData={instructorData} />;
            case 'view_courses':
                return <ViewCourses renderView={renderView} instructorData={instructorData} />;
            case 'sub_courses':
                return <ViewSubCourses renderView={renderView} instructorData={instructorData} mainCourse={variableData} />;
            case 'view_lessons':
                return <ViewLessons renderView={renderView} instructorData={instructorData} subCourse={variableData} />;
            case 'view_certs':
                return <UsersWithCertificates renderView={renderView} instructorData={instructorData} />;
            default:
                return <MainView renderView={renderView} instructorData={instructorData} />;
        }
    };

    return (
        <>
            {!instructorData && <center><div className='h-screen w-full bg-slate-600 text-dark text-5xl'><img src={loader1} alt='loader' /><br />Loading Your Dashboard...</div></center>}
            {
                instructorData &&
                <div id="page-top">
                    <div id="wrapper">
                        <Sidebar renderView={renderView} />
                        <div id="content-wrapper" className="d-flex flex-column">
                            <div id="content">
                                <Topbar instructorData={instructorData} onProfileUpdate={fetchInstructorData} renderView={renderView} />
                                <div className="container-fluid">
                                    {getViewComponent()}
                                </div>
                            </div>
                            <footer className="sticky-footer bg-white">
                                <div className="container my-auto">
                                    <div className="copyright text-center my-auto">
                                        <span>Copyright &copy; Opecstat</span>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </div>
                    <a className="scroll-to-top rounded" href="#page-top">
                        <i className="fas fa-angle-up"></i>
                    </a>
                </div>
            }
        </>
    );
};

export default InstructorDashboard;
