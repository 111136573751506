import React, { useEffect, useState } from 'react';
import { Button, Container, Row, Col, Card, Alert, ListGroup, Image, Carousel } from 'react-bootstrap'; // Ensure you have react-bootstrap installed
import logo from '../../../assets/images/opec10.png'; // Import the logo image
import Cookies from 'js-cookie';


// Import the images for the gallery
import img1 from '../../../assets/images/copy_images/course-page-copy/InShot_20241216_143046866.jpg';
import img2 from '../../../assets/images/copy_images/course-page-copy/InShot_20241216_143403930.jpg';
import img3 from '../../../assets/images/copy_images/course-page-copy/InShot_20241216_143857994.jpg';
import img4 from '../../../assets/images/copy_images/course-page-copy/InShot_20241218_200648872.jpg';
import img5 from '../../../assets/images/copy_images/course-page-copy/InShot_20241218_200914769.jpg';
import img6 from '../../../assets/images/copy_images/course-page-copy/InShot_20241218_201051405.jpg';
import img7 from '../../../assets/images/copy_images/course-page-copy/InShot_20241222_084839776.jpg';
import img8 from '../../../assets/images/copy_images/course-page-copy/InShot_20241222_085159826.jpg';
import img9 from '../../../assets/images/copy_images/course-page-copy/InShot_20241222_085754606.jpg';
import img10 from '../../../assets/images/copy_images/course-page-copy/InShot_20241223_162957402.jpg';
import img11 from '../../../assets/images/copy_images/course-page-copy/InShot_20241223_163150443.jpg';
import img12 from '../../../assets/images/copy_images/course-page-copy/InShot_20241223_163757357.jpg';
import PaystackSubscription from '../../paymentComponents/PaystackSubscription';

const CoursePage = () => {
    const [timeLeft, setTimeLeft] = useState(600); // Initial 10 minutes in seconds
    const [discountActive, setDiscountActive] = useState(true);
    const [showNotification, setShowNotification] = useState(false);
  
    useEffect(() => {
      // Initialize the timer from cookies or set the default value
      const savedTime = Cookies.get("timeLeft");
      if (savedTime) {
        setTimeLeft(parseInt(savedTime, 10));
      } else {
        Cookies.set("timeLeft", 600, { expires: 0.5 }); // Expire in 12 hours
      }
  
      const discountExpired = Cookies.get("discountExpired");
      if (discountExpired === "true") {
        setDiscountActive(false); // Disable discount if already expired
      }
    }, []);
  
    useEffect(() => {
      // Manage the countdown timer
      let timer;
      if (timeLeft > 0 && discountActive) {
        timer = setInterval(() => {
          setTimeLeft((prevTime) => {
            const newTime = prevTime - 1;
            Cookies.set("timeLeft", newTime, { expires: 0.5 }); // Update cookie
            return newTime;
          });
        }, 1000);
      } else if (timeLeft === 0 && discountActive) {
        // Handle when the timer reaches zero
        setDiscountActive(false);
        Cookies.set("discountExpired", "true");
        Cookies.remove("timeLeft"); // Clear time cookie after expiration
      }
  
      return () => clearInterval(timer); // Cleanup timer on unmount
    }, [timeLeft, discountActive]);
  
    const formatTime = (timeInSeconds) => {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;
      return `${minutes < 10 ? `0${minutes}` : minutes}:${
        seconds < 10 ? `0${seconds}` : seconds
      }`;
    };
  
    const originalPrice = 67000; // Original price in Naira
    const discountedPrice = 5000; // Discounted price in Naira
    const price = discountActive ? discountedPrice : originalPrice;
  
    const handleSuccess = (response) => {
      console.log("Payment Successful", response);
    };
  
    const handleFailure = (error) => {
      console.log("Payment Failed", error);
    };
     
  return (
    <Container className="bg-dark text-white py-1">
      <Row className="my-1">
        {/* Logo */}
        <Col md={12} className="text-center">
          <center><Image src={logo} alt="Opecstat Logo" width={200} height={200} /></center>
        </Col>
      </Row>

      <Row className="my-5">
        {/* Title and Description */}
        <Col md={12} className="text-center">
          <h1 className="display-4 font-weight-bold text-light">
            Master Python, Front-End Web Development & Back-End Web Development <br/><small className='fs-4'>In just 3 Months</small>
          </h1>
          <p className="lead">
            Unlock your full-stack development potential with our comprehensive Onine masterclass course. Learn Python, Front-End Web Development (HTML, CSS, JavaScript, React), and Back-End Web Development using Node.js, Express.js, and MySQL.
          </p>
        </Col>
      </Row>

      {/* Discount Timer */}
      {discountActive && (
        <Row>
          <Col md={12} className="text-center">
            <Alert variant="success">
              <h4>Limited Time Offer!<br/> <b>Discount Ends In:</b></h4>
              <h2>{formatTime(timeLeft)}</h2>
            </Alert>
          </Col>
        </Row>
      )}

      <Row>
        {/* Course Price and Enroll Button */}
        <Col md={6}>
          <Card className="bg-secondary text-white shadow-lg">
            <Card.Body>
              <h3 className="text-light text-center">{discountActive ? 'Discount' : 'Course'} Price: ₦{discountActive ? '5,000' : '67,000'}</h3>
              <p className="text-center">
                {discountActive ? (
                  <span>
                    <big className='fw-bold'>Original Price: ₦67,000</big> <br/> Now <b>₦5,000</b>! Hurry, the offer ends in <b>{formatTime(timeLeft)}</b>.
                  </span>
                ) : (
                  <span>The discount has ended. The course is now back to ₦67,000.</span>
                )}
              </p>
              <div className="text-center">
                <PaystackSubscription
                    prefilledEmail=""
                    prefilledName=""
                    userId=""
                    amount={price}
                    onSuccess={handleSuccess}
                    onFailure={handleFailure}
                    buttonDisplayText="Enroll Now"
                    btnColor = "info"
                    payCode={90}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Course Features */}
        <Col md={6}>
          <Card className="bg-secondary text-white shadow-lg">
            <Card.Body>
              <h3 className="text-light">What You'll Learn:</h3>
              <ListGroup variant="flush">
                <ListGroup.Item className="bg-secondary border-0 text-light">You will learn Python programming for building real-world applications.</ListGroup.Item>
                <ListGroup.Item className="bg-secondary border-0 text-light">You will master Front-End Web Development using HTML, CSS, JavaScript, and React to create interactive and responsive web pages.</ListGroup.Item>
                <ListGroup.Item className="bg-secondary border-0 text-light">You will learn Back-End Web Development with Node.js, Express.js, and MySQL to build scalable and efficient server-side applications.</ListGroup.Item>
                <ListGroup.Item className="bg-secondary border-0 text-light">Learn to integrate APIs and handle asynchronous operations in Node.js.</ListGroup.Item>
                <ListGroup.Item className="bg-secondary border-0 text-light">Build full-stack applications combining your front-end and back-end knowledge.</ListGroup.Item>
                <ListGroup.Item className="bg-secondary border-0 text-light">You will create secure RESTful APIs and learn authentication techniques using JWT.</ListGroup.Item>
                <ListGroup.Item className="bg-secondary border-0 text-light">Understand the use of databases and learn to create and manipulate MySQL databases for storing and retrieving data.</ListGroup.Item>
                <ListGroup.Item className="bg-secondary border-0 text-light">Develop deployment skills for hosting your web applications on popular cloud platforms.</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        {/* Second What You Will Learn List */}
        <Col md={12} className="my-5">
          <h3 className="text-center text-light"><b>What You'll Learn (Continued)</b></h3>
          <Row>
            <Col md={6}>
              <ul className="list-group list-group-flush">
                <li className="list-group-item bg-dark text-light">You will be given Eight(8) highly in-demand Python Programming textbooks worth ₦48,000 FREE.</li>
                <li className="list-group-item bg-dark text-light">You will receive Free Mentorship throughout your learning journey.</li>
                <li className="list-group-item bg-dark text-light">You will be added to our community of current and Alumni students doing great things in the industry.</li>
                <li className="list-group-item bg-dark text-light">You will learn about Machine & Deep Learning Concepts and how to use them effectively.</li>
              </ul>
            </Col>
            <Col md={6}>
              <ul className="list-group list-group-flush">
                <li className="list-group-item bg-dark text-light">You will be taught Full-Stack Web Development from both Front-End to Back-End.</li>
                <li className="list-group-item bg-dark text-light">You will learn to use MySQL for managing relational databases in your web applications.</li>
                <li className="list-group-item bg-dark text-light">You will understand how to work with APIs and external services for seamless integration.</li>
                <li className="list-group-item bg-dark text-light">Upon course completion, you will receive a high-valued, CAC-recognized certificate.</li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        {/* Introduction Video Embed */}
        <Col md={12} className="text-center my-5">
          <h2>Watch Our Introduction Video</h2>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              src="https://player.vimeo.com/video/730283471" // Replace with your actual video ID
              allowFullScreen
              title="Course Introduction"
            ></iframe>
          </div>
        </Col>
      </Row>

      <Row className="my-5">
        {/* Opecstat Background and Testimonials */}
        <Col md={12} className="text-center">
          <h2>Why Choose Opecstat?</h2>
          <p>
            Opecstat has successfully trained numerous students who are now excelling in their tech careers. Our goal is not just to teach you how to code, but to help you secure a job in the tech industry. We go beyond coding tutorials and provide mentorship, real-world projects, and guidance to ensure you're ready for your next job opportunity.
          </p>
        </Col>
      </Row>

      {/* Gallery Section with Carousel */}
        <Row className="my-5">
            <Col md={12} className="text-center">
                <h2 className="text-light">Our Gallery</h2>
                <Carousel>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img1}
                    alt="Gallery Image 1"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 1</h3>
                    <p>Description of image 1.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img2}
                    alt="Gallery Image 2"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 2</h3>
                    <p>Description of image 2.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img3}
                    alt="Gallery Image 3"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 3</h3>
                    <p>Description of image 3.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img7}
                    alt="Gallery Image 7"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 7</h3>
                    <p>Description of image 7.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img8}
                    alt="Gallery Image 8"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 8</h3>
                    <p>Description of image 8.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img9}
                    alt="Gallery Image 9"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 9</h3>
                    <p>Description of image 9.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img10}
                    alt="Gallery Image 10"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 10</h3>
                    <p>Description of image 10.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img11}
                    alt="Gallery Image 11"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 11</h3>
                    <p>Description of image 11.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img12}
                    alt="Gallery Image 12"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 12</h3>
                    <p>Description of image 12.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                </Carousel>
            </Col>
        </Row>

        <Row>
            {/* Course Price and Enroll Button */}
            <Col md={12}>
            <Card className="bg-secondary text-white shadow-lg">
                <Card.Body>
                <h3 className="text-light text-center">{discountActive ? 'Discount' : 'Course'}: ₦{discountActive ? '5,000' : '67,000'}</h3>
                <p className="text-center">
                    {discountActive ? (
                    <span>
                        <b>Original Price: ₦67,000</b> <br/> Now <b>₦5,000</b>! Hurry, the offer ends in <b>{formatTime(timeLeft)}</b>.
                    </span>
                    ) : (
                    <span>The discount has ended. The course is now back to ₦67,000.</span>
                    )}
                </p>
                <div className="text-center">
                    <PaystackSubscription
                    prefilledEmail=""
                    prefilledName=""
                    userId=""
                    amount={price}
                    onSuccess={handleSuccess}
                    onFailure={handleFailure}
                    buttonDisplayText="Enroll Now"
                    btnColor = "info"
                    payCode={90}
                    />
                </div>
                </Card.Body>
            </Card>
            </Col>
        </Row>
    </Container>
  );
};

export default CoursePage;
