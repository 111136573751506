import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CertificateUploader = ({ instructorData }) => {
  const [courses, setCourses] = useState([]);
  const [email, setEmail] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [certificate, setCertificate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Fetch courses for the given instructor ID
    const fetchCourses = async () => {
      try {
        console.log("InstrictirData: ", JSON.stringify(instructorData,null,2))
        const response = await axios.get(`https://www.opecstat.com/opecstat_server/course/getCourseByInstructorId/${instructorData._id}`);
        setCourses(response.data.courses || []);
      } catch (error) {
        console.error('Error fetching courses:', error);
        setMessage('Unable to load courses. Please try again later.');
      }
    };

    fetchCourses();
  }, [instructorData]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!email || !selectedCourse || !certificate) {
      setMessage('All fields are required.');
      return;
    }

    setLoading(true);
    setMessage('');

    const formData = new FormData();
    formData.append('userEmail', email); // Assuming email maps to userId
    formData.append('courseId', selectedCourse);
    formData.append('certImage', certificate);

    try {
      const response = await axios.post('https://www.opecstat.com/opecstat_server/instructor/upload-certificate', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setMessage(response.data.message || 'Certificate uploaded successfully!');
    } catch (error) {
      console.error('Error uploading certificate:', error);
      setMessage(
        error.response?.data?.error || 'An error occurred while uploading the certificate.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center">Upload Certificate</h2>
      <form onSubmit={handleFormSubmit} className="shadow p-4 rounded">
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            User Email
          </label>
          <input
            type="email"
            id="email"
            className="form-control"
            placeholder="Enter user email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="course" className="form-label">
            Select Course
          </label>
          <select
            id="course"
            className="form-select"
            value={selectedCourse}
            onChange={(e) => setSelectedCourse(e.target.value)}
            required
          >
            <option value="">-- Select a Course --</option>
            {courses.filter((item)=>item.main_c_id===null).map((course) => (
              <option className='text-dark' key={course._id} value={course._id}>
                {course.title}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="certificate" className="form-label">
            Upload Certificate
          </label>
          <input
            type="file"
            id="certificate"
            className="form-control"
            onChange={(e) => setCertificate(e.target.files[0])}
            required
          />
        </div>
        {message && <div className="alert alert-info">{message}</div>}
        <button type="submit" className="btn btn-primary w-100" disabled={loading}>
          {loading ? 'Uploading...' : 'Upload Certificate'}
        </button>
      </form>
    </div>
  );
};

export default CertificateUploader;
