import '../../assets/styles/style.css';
import '../../assets/styles/responsive.css';
import '../../assets/styles/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../assets/images/opec10.png';
import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const VerifyPageView = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  const [activationCode, setActivationCode] = useState('');
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [isSubmittingEmail, setIsSubmittingEmail] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setIsSubmittingEmail(true);
    setMessage('');

    try {
      const response = await fetch('https://opecstat.com/opecstat_server/user/check-email-activation-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        const data = await response.json();
        setActivationCode(data.activationCode || 'No activation code found.');
        setShowCodeInput(true);
      } else {
        const error = await response.json();
        setMessage(`Error: ${error.message}`);
      }
    } catch (error) {
      setMessage('Error retrieving activation code. Please try again.');
    } finally {
      setIsSubmittingEmail(false);
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    setIsVerifying(true);
    setMessage('');

    try {
      const response = await fetch('https://opecstat.com/opecstat_server/user/verify-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code, email }),
      });

      if (response.ok) {
        const data = await response.json();
        setMessage(`Code verified successfully: ${data.message}`);
        setShowModal(true);
      } else {
        const error = await response.json();
        setMessage(`Error: ${error.message}`);
      }
    } catch (error) {
      setMessage('Error verifying code. Please try again.');
    } finally {
      setIsVerifying(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setEmail('');
    setCode('');
    setMessage('');
    setActivationCode('');
    setShowCodeInput(false);
  };

  return (
    <div className="d-flex align-items-center justify-content-center vh-100 bg-light">
      <div className="card shadow p-4" style={{ maxWidth: '400px', width: '100%' }}>
        <div className="col-md-12 mb-2">
          <center>
            <img src={logo} alt="Opecstat Logo" width={80} height={80} />
          </center>
        </div>
        <h1 className="h4 text-center mb-3">Verify Your Code</h1>
        {!showCodeInput ? (
          <form onSubmit={handleEmailSubmit}>
            <p className="text-center text-muted mb-4">
              Enter your email to retrieve your activation code:
            </p>
            <div className="form-group mb-3">
              <input
                type="email"
                className="form-control"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary w-100"
              disabled={isSubmittingEmail}
            >
              {isSubmittingEmail ? 'Submitting...' : 'Submit Email'}
            </button>
          </form>
        ) : (
          <form onSubmit={handleCodeSubmit}>
            <p className="text-center text-muted mb-4">
              Your activation code: <strong className='text-primary'>{activationCode}</strong>
            </p>
            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter your code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary w-100"
              disabled={isVerifying}
            >
              {isVerifying ? 'Verifying...' : 'Verify Code'}
            </button>
          </form>
        )}
        {message && (
          <div className="alert alert-danger mt-3" role="alert">
            {message}
          </div>
        )}
      </div>

      {/* Modal */}
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton className="border-0 bg-light">
          <Modal.Title className="text-center w-100 font-weight-bold text-primary">
            Activation Successful
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center py-4">
          <p className="h5 mb-3">Your activation was successful!</p>
          <p className="h6">You will receive an enrollment email shortly.</p>
        </Modal.Body>
        <Modal.Footer className="border-0 d-flex justify-content-center">
          <Button variant="primary" onClick={closeModal} className="px-4 py-2">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VerifyPageView;
